body {
  background: #f0f2f5;
}

.formControlArea {
  display: block;
  background-color: white;
  padding: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  /* min-width: 300px; */
  max-width: 500px;
}

.formControlArea .formActions button {
  display: block;
  padding: 0px;
}

/* HC Styles */

.ant-menu-root {
  background-color: #004e2b !important;
}
.ant-menu-submenu-title {
  color: #e7e7e7 !important;
}

.ant-layout-header {
  background-color: #004e2b !important;
}
/*
 .ant-menu-submenu-open .ant-menu-submenu-title {
  color: #e7e7e7 !important;
}

.ant-menu-item-active {
  background-color: #e7e7e7 !important;
  color: black !important;
}
.ant-menu-item-active {
  background-color: #e7e7e7 !important;
}

.ant-menu-item-active a:hover {
  color: black !important;
}

.ant-menu-item-selected {
  background-color: #e7e7e7 !important;
  color: black !important;
}

.ant-menu-item-selected a {
  color: black !important;
}  */

/* .ant-menu-submenu-selected {
  background-color: #00ad46 !important;
} */

.button-backtop {
  height: 40px;
  width: 120px;
  line-height: 40px;
  border-radius: 10px;
  background-color: #058137;
  color: #fff;
  text-align: center;
  font-size: 14px;
}

/* ------------------------------------------- */

.ant-form-item-label label {
  width: 150px;
  font-size: 13px !important;
  line-height: 16px;
  text-align: left !important;
  overflow-wrap: break-word;
  white-space: normal;
}

ant-form-item-label > {
  font-size: 12px !important;
}

.ant-input[disabled] {
  cursor: text !important;
}

/* is not applied in the moment - maybe wrong css class */
.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-input[disabled] {
  color: rgba(0, 50, 0, 0.5);
  cursor: not-allowed;
  opacity: 1;
}

.panel-two-column .ant-collapse-content-box {
  display: grid;
  align-items: top;
  grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
  column-gap: 1rem;
}

.panel-grid .ant-collapse-content-box,
.dyn-grid {
  display: grid;
  align-items: top;
  grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
  column-gap: 1rem;
}

.formWorkflow .ant-steps-vertical .ant-steps-item-content {
  min-height: 35px;
}

.hoverAble .ant-steps-item-container:hover .ant-steps-icon {
  color: black;
}
.hoverAble .ant-steps-item-container:hover .ant-steps-item-title {
  background-color: #d1d1d1;
  border-radius: 3px;
  cursor: pointer;
}

.ant-steps-item-title {
  padding-left: 5px;
  padding-right: 5px;
}

.ql-disabled .ql-toolbar {
  display: none;
}

.ql-disabled .ql-editor {
  border-top-color: rgb(204, 204, 204);
  border-top-style: solid;
  border-top-width: 1px;
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ql-editor {
  overflow-y: auto;
  resize: vertical;
}

.ant-btn-primary {
  background-color: #8a8a8a !important;
  border-color: #8d8d8d !important;
}

.sorter {
  cursor: pointer;
  margin-left: 7px;
}

.sortOnHover {
  width: 15px;
  height: 10px;
  display: inline-block;
}
.sortOnHover .sorterIcon {
  display: none;
}

.th:hover .sortOnHover .sorterIcon {
  display: inline-block;
}

.pagination {
  margin-top: 10px !important;
}

table:hover,
tr:hover,
thead:hover {
  background: none !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}
